/* eslint-disable */
/**
 * 【温馨提示】，假如不想背锅，没事别动这个文件里面的任何东西，会导致整个项目网络请求崩溃,想好在动
 */
import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
import { getLocalStorageItem, deleteLocalStorageItem } from '@/utils/localstorage/LocalstorageKit'
import Vue from 'vue'
import store from '@/store';
const app = axios.create({
  timeout: 30000,
  baseURL: process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API : '',
  responseType: 'blob',
});
//HttpRequest拦截器
app.interceptors.request.use(
  async (config) => {
    // 获取token
    let result = await new Promise((resolve, reject) => {
      let token = (getLocalStorageItem("token") ? getLocalStorageItem("token") : "");
      config.headers.authorization = token ? `Bearer ${token}` : '';
      config.headers.platform = 'pc';
      resolve(config);
    });
    return result;
  },
  (err) => {
    return Promise.reject(err);
  }
);


//HttpResponse拦截器
app.interceptors.response.use(
  (response) => {
    if (response.data.state === 'FAIL' && response.data.code == "402") {
      Vue.prototype.$confirm(response.data.msg, '提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((resp) => {
        //清除当前token信息和登录信息
        deleteLocalStorageItem("token");
        deleteLocalStorageItem("userInfo");
        store.commit("removeTags");
        store.commit("SET_ISLOGIN", false)
        window.location.href = "/login"
      }).catch(error);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error)
  }
);


/**
 * GET请求方式
 * @param {*} url
 * @param {*} params
 */
export function getBlob(url, params = {}) {
  return new Promise((resolve, reject) => {
    app.get(url, {
      params: params
    })
      .then(response => {
        if (response.data.code === '200') {
          //返回成功处理  这里传的啥 后续调用的时候 res就是啥
          resolve(response);//我们后台所有数据都是放在返回的data里所以这里统一处理了
        } else {
          //错误处理
          resolve(response);
        }
      })
      .catch(err => {
        reject(err);
        let message = '请求失败！请检查网络';
        //错误返回
        if (err.response) {
          message = err.response.data.message;
        }
      })
  })
}

/**提交JSON数据
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postBlob(url, data = {}) {
  return new Promise((resolve, reject) => {
    app.post(url, data)
      .then(response => {
        if (response.data.code === '200') {
          resolve(response);
        } else {
          resolve(response);
        }
      }, err => {
        reject(err);
        let message = '请求失败！请检查网络';
        if (err.response) {
          message = err.response.data.message;
        }
      })
  })
}