<template>
    <div>
        <el-table ref="multipleTable" :data="records.records" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleSelectionChange" :header-cell-style="headClass" v-loading="loading" row-key="id">
            <el-table-column type="selection" width="55" v-if="showSelection" :key="Math.random()">
            </el-table-column>
            <slot name="content"></slot>
        </el-table>
        <div style="margin-top: 20px">
            <div class="pagenation">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :page-sizes="pageSizeList" :page-size="pageSize" :current-page="pageNum"
                    layout="total, sizes, prev, pager, next, jumper" :total="records.total || records.totalRow"
                    v-if="records.total > 0 || records.totalRow > 0">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        records: {
            type: Object,
            default: function () {
                return {};
            }
        },
        loading: {
            type: Boolean,
            default: true,
        },
        showSelection: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pageSize: 20,
            pageNum: 1,
            multipleSelection: [],
            pageSizeList: [10, 20, 50, 100],
        }
    },
    created() {
        this.doSearch();
    },
    methods: {
        headClass() {
            return "background:#FAFAFA;color:#666666";
        },
        handleSelectionChange(rows) {
            let ids = [];
            if (rows) {
                rows.forEach((row) => {
                    ids.push(row.id);
                });
            }
            this.multipleSelection = ids;
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.doSearch();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.doSearch();
        },
        doSearch() {
            this.$emit("searchHandler", {
                pageSize: this.pageSize,
                pageNum: this.pageNum,
            });
        },
    }
}
</script>

<style lang="scss" scope>
.pagenation {
    display: flex;
    justify-content: center;
}

.el-table {
    th .cell {
        color: #4F4D46;
    }

    td .cell .el-button {
        font-size: 14px;
    }
}
</style>