<!--交易流水-->
<template>
    <div>
        <el-form :inline="true" class="form" label-position="right">
            <el-form-item label="交易类型:">
                <el-select v-model="search.businessType" placeholder="请选择交易类型" clearable>
                    <el-option v-for="(item, index) in accountRecordBusinessTypeList" :key="index" :label="item.name"
                        :value="item.no" />
                </el-select>
            </el-form-item>
            <el-form-item label="付款方:">
                <el-input v-model="search.payer" placeholder="请输入付款方"></el-input>
            </el-form-item>
            <el-form-item label="收款方:">
                <el-input v-model="search.payee" placeholder="请输入收款方"></el-input>
            </el-form-item>
            <el-form-item label="交易状态:">
                <el-select v-model="search.transactionStatus" placeholder="请选择交易状态" clearable>
                    <el-option v-for="(item, index) in transactionStatusList" :value="item.no" :label="item.name"
                        :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="交易流水号:">
                <el-input v-model="search.reqSeqId" placeholder="请输入交易流水号"></el-input>
            </el-form-item>
            <el-form-item label="汇付全局流水号:">
                <el-input v-model="search.hfSeqId" placeholder="请输入汇付全局流水号"></el-input>
            </el-form-item>
            <el-form-item label="交易时间:">
                <el-date-picker v-model="TimeArr" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" :picker-options="pickerOptions" :default-time="defaultTimeRange" clearable
                    format="yyyy-MM-dd HH:mm">
                </el-date-picker>
            </el-form-item>
            <div></div>
            <el-form-item>
                <el-button type="primary" @click="headSearch">查询</el-button>
                <el-button type="info" @click="reset">重置</el-button>
            </el-form-item>

        </el-form>
        <table-compnent :records="tableData" :loading="loading" @searchHandler="doSearch">
            <template v-slot:content>
                <el-table-column prop="businessTypeName" label="交易类型" width="120"></el-table-column>
                <el-table-column label="付款方" width="240">
                    <template slot-scope="scope">
                        {{ `${scope.row.payerName} ${scope.row.payerNumber}`.trim() }}
                    </template>
                </el-table-column>
                <el-table-column label="收款方" width="240">
                    <template slot-scope="scope">
                        {{ `${scope.row.payeeName} ${scope.row.payeeNumber}`.trim() }}
                    </template>
                </el-table-column>
                <el-table-column label="交易状态" width="120">
                    <template slot-scope="scope">
                        <span
                            :style="{ 'color': scope.row.transactionStatusName.includes('成功') ? '#6BCA35' : scope.row.transactionStatusName.includes('失败') ? '#FF4D4F' : '#2C6AFF' }">{{
                                    scope.row.transactionStatusName
                            }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="交易金额(元)" width="120">
                    <template slot-scope="scope">
                        <span :style="{ 'color': scope.row.type == '2' ? '#FA8C16' : '#999999' }">{{
                                (scope.row.type == 2 ? '+' : '-') + scope.row.transactionAmount
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="reqSeqId" label="交易流水号" width="240"></el-table-column>
                <el-table-column prop="hfSeqId" label="汇付全局流水号" width="400"></el-table-column>
                <el-table-column prop="opTimeStr" label="交易时间" width="200"></el-table-column>
                <el-table-column label="操作" width="120" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="toDetail(scope.row)">查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </table-compnent>
    </div>
</template>
  
<script>
import basePage from "../../base/basePage.vue";
import TableCompnent from "@/components/table/baseTablesComponents";
import TripFinancialApi from "@/api/TripFinancialApi";
import { CommonDateFormat } from "@/utils/DateUtils";
export default {
    extends: basePage,
    components: {
        TableCompnent
    },
    data() {
        let that = this;
        return {
            tableData: {},
            search: {
                businessType: "",
                endTimeStr: "",
                hfSeqId: "",
                pageNum: 20,
                pageSize: 0,
                payee: "",
                payer: "",
                eqSeqId: "",
                startTimeStr: "",
                transactionStatus: ""
            },
            TimeArr: [],
            accountRecordBusinessTypeList: [],
            transactionStatusList: [],
            page: {},
            loading: false,
            defaultTimeRange: ["00:00:00", "00:00:00"],
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    that.pickerMinDate = minDate.getTime();
                    if (maxDate) {
                        that.pickerMinDate = null;
                    }
                },
                disabledDate: (time) => {
                    if (that.pickerMinDate) {
                        const day30 = (30 - 1) * 24 * 3600 * 1000;
                        let maxTime = that.pickerMinDate + day30;
                        if (maxTime > new Date()) {
                            maxTime = new Date();
                            return (
                                time.getTime() > maxTime ||
                                time.getTime() < that.pickerMinDate - day30
                            );
                        } else {
                            return (
                                maxTime < time.getTime() ||
                                time.getTime() < that.pickerMinDate - day30
                            );
                        }
                    }

                    return time.getTime() > Date.now();
                },
            },
        };
    },
    created() {
        this.findAccountRecordBusinessTypeAllSelect();
        this.findTransactionStatusList();
        this.headSearch();
    },
    watch: {
        TimeArr(newVal) {
            console.log(newVal);
            if (newVal.length == 2) {
                this.search.startTimeStr = CommonDateFormat(
                    new Date(newVal[0]),
                    "YYYY-mm-dd HH:MM:SS"
                );
                this.search.endTimeStr = CommonDateFormat(
                    new Date(newVal[1]),
                    "YYYY-mm-dd HH:MM:SS"
                );
            } else {
                this.search.startTimeStr = "";
                this.search.endTimeStr = "";
            }
        }
    },
    methods: {
        reset() {
            this.search = this.$options.data().search;
            this.TimeArr = [];
            this.doSearch({ pageSize: 20, pageNum: 1 });
        },
        headSearch() {
            this.doSearch({ pageSize: 20, pageNum: 1 });
        },
        doSearch(params) {
            this.loading = true;
            this.page = params;
            this.search.pageSize = this.page.pageSize;
            this.search.pageNum = this.page.pageNum;
            // if (this.TimeArr == null) {
            //     this.search.startTimeStr = "";
            //     this.search.endTimeStr = "";
            // } else if (this.TimeArr && this.TimeArr.length != 0) {
            //     this.search.startTimeStr = this.TimeArr[0];
            //     this.search.endTimeStr = this.TimeArr[1];
            // }
            TripFinancialApi.findTransactionRecordList(this.search).then((resp) => {
                if (resp.code === '200') {
                    this.tableData = resp.data;
                    this.loading = false;
                }
            });
        },
        toDetail(row) {
            this.navTo(`/transactionRecordDetails/${row.id}_${row.type}`)
        },
        findAccountRecordBusinessTypeAllSelect() {
            TripFinancialApi.findAccountRecordBusinessTypeAllSelect().then((resp) => {
                if (resp.code === '200') {
                    // console.log(resp);
                    this.accountRecordBusinessTypeList = resp.data;
                }
            });
        },
        findTransactionStatusList() {
            TripFinancialApi.findTransactionStatusSelect().then((resp) => {
                if (resp.code === '200') {
                    // console.log(resp);
                    this.transactionStatusList = resp.data;
                }
            });
        }
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form .el-input,
.el-select,
.el-date-editor {
    width: 280px;
}

.form {
    display: grid;
    grid-template-columns: 370px auto auto;
    grid-template-rows: auto;
}

::v-deep .el-form-item__label {
    white-space: nowrap;
    display: inline-block;
    color: #666666;
}

.el-form-item {
    display: flex;
    justify-content: right;
    margin-bottom: 16px;
}
</style>