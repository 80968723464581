//资金相关接口
import * as AppHttpKit from '@/utils/http/AppHttpKit';
import * as apphttp from '@/utils/http/apphttp'
const TripFinancialApi = {

    /**
     * 司机余额相关接口
     */
    findDriverAccountBalanceList: (data) => AppHttpKit.postJSON(`/v1/driverAccount/findDriverAccountBalanceList`, data),//查询司机余额列表
    findDriverRecordList: (data) => AppHttpKit.postJSON(`/v1/tripUserAccountRecord/findDriverRecordList`, data),//查询司机流水列表
    findAccountRecordBusinessTypeAllSelect: () => AppHttpKit.postJSON(`/v1/tripUserAccountRecord/findAccountRecordBusinessTypeAllSelect`),//获取流水的业务类型下拉框--全部
    findAccountApplyStatusSelect: () => AppHttpKit.postJSON(`/v1/tripUserAccountRecord/findAccountApplyStatusSelect`),//获取流水的业务类型下拉框--部分

    /**
     * 交易流水相关接口     * 
     */
    findTransactionStatusSelect: () => AppHttpKit.postJSON(`/v1/tripUserAccountRecord/findTransactionStatusSelect`),//获取交易状态下拉框
    findTransactionRecordList: (data) => AppHttpKit.postJSON(`/v1/tripUserAccountRecord/findTransactionRecordList`, data),//查询交易流水列表
    findTransactionRecordDetail: (data) => AppHttpKit.postJSON(`/v1/tripUserAccountRecord/findTransactionRecordDetail`, data),//查询交易流水详情
    queryEventLogDetail: (data) => AppHttpKit.get(`/v1/queryEventLogDetail/${data}`),//查询行程详情
    findSettleRecordDetail: (data) => AppHttpKit.postJSON(`/v1/tripUserAccountRecord/findSettleRecordDetail`, data),//查询交易流水列表



}
export default TripFinancialApi;